/* searchgsa */
//gsa search

var gsa = {

    suggestlength: 2, //start suggest queries when term has this length
    suggest: '/gsa/suggest?', //url suggest
    search: '/gsa/search?', //url search
    max: 10, //max suggestion
    access: 'p', //query stuff
    format: 'rich',
    output: 'xml_no_dtd',
    proxyreload: 1,
    sort: 'date:D:L:d1',
    oe: 'UTF-8',
    ie: 'UTF-8',
    ud: 1,
    exclude_apps: 1,
    entqrm: 0,
    searchinput: '.gsaSearchInputField', //input field selector
    top: -22, //diff for autosuggestbox position

    init: function() {
        gsa.$resultContainer = $('#gsa_content'); //container
        if (gsa.$resultContainer && gsa.$resultContainer.length > 0) {
            gsa.client = gsa.$resultContainer.data('client'); //lang
            gsa.site = gsa.$resultContainer.data('site'); //current site
            gsa.q = gsa.$resultContainer.data('q'); //searchterm
            gsa.url = gsa.search + 'site=' + gsa.site + '&exclude_apps=' + gsa.exclude_apps + '&client=' + gsa.client + '&proxystylesheet=' + gsa.client + '&output=' + gsa.output + '&proxyreload=' + gsa.proxyreload + '&sort=' + gsa.sort + '&oe=' + gsa.oe + '&ie=' + gsa.ie + '&ud=' + gsa.ud + '&ulang=' + gsa.client; //build generic query
            var url = (typeof gsa.q != 'undefined') ? '&q=' + gsa.q : ''; //query param given from previous page
            gsa.events(); //add events
            //load inital dom - empty query
            gsa.load(gsa.url + url, function(data) {
                gsa.$resultContainer.html($(data).filter('div')); //add filtered dom
                gsa.cleanup(); //cleanup old dom //TODO - delete after dom modified
            });
        }
    },

    //add events
    events: function() {
        //event delegate container
        gsa.$resultContainer

        //on keyup - load suggestion
        .on('keyup', gsa.searchinput, function(e) {
            if(e.which == 37 || e.which == 38 || e.which == 39 || e.which == 40) {
                var searchterm = '';
                //navigate down
                if (e.which == 40) {
                    var suggestions = gsa.suggestionsbox.find('li'),
						cur = suggestions.filter('.active');
                    if (cur.length && cur.next().length) {
                       searchterm = cur.removeClass('active').next().addClass('active').text();
                    } else {
                        searchterm = suggestions.removeClass('active').eq(0).addClass('active').text();
                    }
                }
                //navigate up
                if (e.which == 38) {
                    var suggestions = gsa.suggestionsbox.find('li'),
						cur = suggestions.filter('.active');
                    if (cur.length && cur.prev().length) {
                        searchterm = cur.removeClass('active').prev().addClass('active').text();
                    } else {
                        searchterm = suggestions.removeClass('active').last().addClass('active').text();
                    }
                }
                if (searchterm !== '') $(gsa.searchinput).val(searchterm); //update search input, old desktop-syntax $(gsa.searchinput).attr('value', searchterm) did not work on mobile jquery version
                return;
            }
            var q = $(this).val(); //searchterm
            if (q.length > gsa.suggestlength) {
                var url = gsa.suggest + 'q=' + q + '&max=' + gsa.max + '&site=' + gsa.site + '&client=' + gsa.client + '&access=' + gsa.access + '&format=' + gsa.format; //build url
                gsa.loadSuggest(url, gsa.render);
            } else gsa.remove();
        })

        //on blur - remove suggestion
        .on('blur', gsa.searchinput, function(e) {
            window.setTimeout(function() {
                gsa.remove();
            }, 1000);
        })

        //on submit search form
        .on('submit', 'form', function(e) {
            gsa.stop(e);
            var q = $(gsa.searchinput).val();
            gsa.load(gsa.url + '&q=' + q, gsa.callback);
        })

        //on click result links
        .on('click', '.gsaMoreResultsLink, .gsaRepeatSearchAllResults, .gsaDidYouMean, .pagebrowser li a', function(e) {
            gsa.stop(e);
            var cur = $(this),
				url = cur.attr('href');
            if (cur.closest('.pagebrowser').length) {
                url = url.replace('search?', gsa.search);
            }
            gsa.load(url, gsa.callback);
        })

		// open result-link on all p-elements belonging to that result
		.on('click', '.searchForm-main-results > p', function(e) {
			var $this = $(this);
			if ($this.children('a:first-child').length == 0) {
				$this.prev('h3').children('a').trigger('click');
			}
		})

        //on click suggestions
        .on('click', '#gsa_suggestions li', function() {
            $(gsa.searchinput).val($(this).text()).trigger('submit');
        });
    },

    //request gsa
    load: function(url, callback) {
    	$.ajax(url).done(function(data) {
            callback(data);
        });
    },

    //request gsa
    loadSuggest: function(url, callback) {
    	$.ajax({
    		url: url,
    	    type: "GET",
    	    dataType:"jsonp",
    	    success: function(data) {
    	    	callback(data);
    	    }
    	});
    },

    //ajax done callback
    callback: function(data) {
        //console.log(data);
        var html = $(data).filter(function() {
            return this.nodeName == 'DIV' || this.nodeName == 'SPAN'; //filter response
        });
        gsa.$resultContainer.html(html); //add filtered dom
        gsa.cleanup(); //cleanup old dom
    },

    //render suggestions
    render: function(data) {
        //console.log(data);
    	if (data && data.results && data.results.length > 0) {
    		var html = '<ul id="gsa_suggestions" class="small-12">';
    		$.each(data.results, function(k,v) {
    			html += '<li>' + v.name + '</li>';
    	    });
    		html += '</ul>';
    	}
        gsa.remove(); //remove old suggestionsbox
        gsa.$resultContainer.find('.gsaSearchInputField').after(html); //add new one after input field for relative positioning
        gsa.suggestionsbox = $('#gsa_suggestions').css('margin-top', ($(gsa.searchinput).position().top + gsa.top) + 'px'); //position it
    },

    //stop events
    stop: function(e) {
        e.stopPropagation();
        e.preventDefault();
    },

    //remove suggestionsbox
    remove: function() {
        if (gsa.suggestionsbox) gsa.suggestionsbox.remove();
    },

    //clean dom
    cleanup: function() {
        gsa.$resultContainer.find('.gsaSearchInputField').removeAttr('onkeyup'); //remove keyup attr
        $('#search_suggest').remove(); //remove old suggestions table
    }
};

// defer  GSA init until jquery is available
function deferGsaInit() {
	if (window.$) {
		gsa.init();
	} else {
		setTimeout(function() {
			deferGsaInit()
		}, 50);
	}
}

(function() {
	deferGsaInit();
})();

/* /searchgsa */
